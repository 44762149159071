<template>
    <div>
        <b-card no-body>
            <div class="text-center mt-2">
                <h1>Supported Hosters</h1>
                <p class="mb-2">
                    On this page you can check the full list of our supported
                    filehoster services.
                </p>
            </div>
            <h4 class="text-center mt-1">* Total Daily Bandwidth: 200 GB</h4>
            <span class="text-center mb-75"
                >Last Updated: {{ last_updated }}</span
            >
            <b-table
                responsive
                :fields="fields"
                :items="items"
                class="mb-0"
                hover
            >
                <template #cell(#)><div class="stt"></div></template>
                <template #cell(host)="data">
                    <img :src="iconHost(data.value)" />{{ data.value }}
                </template>
                <template #cell(limit_bandwidth_daily)="data">
                    {{ showBwLimit(data.value) }}
                </template>
                <template #cell(limit_file_daily)="data">
                    {{ showFileLimit(data.value) }}
                </template>
                <template #cell(status)="data"
                    ><b-badge pill :variant="statusVariant(data.value)">{{
                        data.value.replace(/^./, (str) => str.toUpperCase())
                    }}</b-badge></template
                >
            </b-table>
        </b-card>
    </div>
</template>

<script>
import { BTable, BBadge, BCard } from "bootstrap-vue";
import formatBytes from "../../mixins/format-bytes";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import moment from "moment";
export default {
    components: {
        BTable,
        BBadge,
        BCard,
        FeatherIcon,
    },
    mixins: [formatBytes],
    created() {
        this.$store
            .dispatch("fetchStatusHost")
            .then((res) => (this.items = res.data.data))
            .catch((err) => console.log(err));
    },
    computed: {
        showBwLimit() {
            return (size) => (size == 0 ? "Unlimited" : this.formatBytes(size));
        },
        showFileLimit() {
            return (file) => (file == 0 ? "Unlimited" : file);
        },
        statusVariant() {
            const statusColor = {
                unstable: "light-warning",
                working: "light-success",
                broken: "light-danger",
            };

            return (status) => statusColor[status];
        },
        iconHost() {
            return (data) => "images/hoster/" + data + ".png";
        },
    },
    data() {
        return {
            count: 0,
            items: [],
            fields: [
                "#",
                "host",
                "status",
                { key: "limit_bandwidth_daily", label: "Bandwidth /day *" },
                { key: "limit_file_daily", label: "Links /day" },
            ],
            last_updated: moment().utc().format("LL"),
        };
    },
    method: {},
};
</script>

<style>
table {
    counter-reset: section;
}
.stt:before {
    counter-increment: section;
    content: counter(section);
}
</style>
